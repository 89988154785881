<template>

  <b-card
    v-if="data"
    footer-tag="footer"
    no-body
  >
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title>
          Bilgilendirme Notları
        </b-card-title>
        <b-card-sub-title />
      </div>
      <!--/ title and subtitle -->

      <!-- badge -->
      <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <h5 class="font-weight-bolder mb-0 mr-1" />

      </div>
      <!--/ badge -->
    </b-card-header>
    <!-- eslint-disable vue/no-v-html -->
    <b-card-body>
      <b-list-group>
        <b-list-group-item
          v-for="content in data"
          :key="content.id"
          :to="{ name: 'content-show', params: { id: content.id } }"
          class="flex-column align-items-start"
        >

          <div class="d-flex w-100 justify-content-between">
            <h5
              class="mb-1"
            >
              {{ content.title }}
            </h5>
            <small class="text-secondary"> {{ content.user.name+' '+content.user.surname }} {{ $format_datetime(content.created_at) }}</small>
          </div>
          <b-card-text
            class="mb-1 cut-text"
            v-html="content.content"
          />

        </b-list-group-item>

      </b-list-group>

    </b-card-body>
    <template #footer>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="flat-primary"
        :to="{path:'/content/show-list/notes' }"
      >
        Devamını Gör
      </b-button>
    </template>
  </b-card>

</template>

<script>
import {
  BCard, BListGroup, BListGroupItem,
  BCardHeader, BCardText, BButton,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BListGroup,
    BButton,
    BListGroupItem,
    BCardHeader,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },

  },
  data() {
    return {

    }
  },
  created() {

  },

}
</script>
<style>
.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
