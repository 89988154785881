<template>
  <b-card v-if="data">
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title>
          Devam Eden Kampanyalar
        </b-card-title>
        <b-card-sub-title />
      </div>
      <!--/ title and subtitle -->

      <!-- badge -->
      <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <h5 class="font-weight-bolder mb-0 mr-1" />

      </div>
      <!--/ badge -->
    </b-card-header>
    <b-card-body>
      <swiper
        v-if="data"
        class="swiper-paginations"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <swiper-slide
          v-for="(item,index) in data"
          :key="index"
        >
          <b-link :to="{ name: 'content-show', params: { id: item.id } }">
            <b-img
              :src="item.featured_image_url"
              fluid
            />
          </b-link>

        </swiper-slide>

        <div
          slot="pagination"
          class="swiper-pagination"
        />
      </swiper>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  Swiper, SwiperSlide,
} from 'vue-awesome-swiper'
import {
  BImg, BCardHeader,
  BCardBody,
  BCardTitle,
  BCardSubTitle, BCard, BLink,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    BCard,
    Swiper,
    SwiperSlide,
    BImg,
    BLink,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },

  },
  data() {
    return {

      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/banner/banner-12.jpg') },
        { img: require('@/assets/images/banner/banner-9.jpg') },
        { img: require('@/assets/images/banner/banner-8.jpg') },
        { img: require('@/assets/images/banner/banner-7.jpg') },
        { img: require('@/assets/images/banner/banner-20.jpg') },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },

      },
    }
  },
}
</script>
