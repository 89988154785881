<template>

  <b-card
    :bg-variant="bg"
    :to="to"
    text-variant="white"
  > <b-link
    :to="to"
  >
    <h2 class="mb-1 mt-50 text-white text-center">
      {{ title }}
    </h2>
    <b-card-text class="text-white text-center">
      {{ description }}
    </b-card-text>
  </b-link>
  </b-card>

</template>

<script>
import {
  BCard, BCardText, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    bg: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
    }
  },
}
</script>
