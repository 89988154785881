<template>
  <section id="dashboard-ecommerce">
    <b-row
      v-if="dashboardData"
      class="match-height"
    >

      <b-col
        v-if="(typeof(dashboardData.my_targets_monthly) !=='undefined')"
        md="12"
      >
        <congratulation-card
          v-if="Number(dashboardData.my_targets_monthly[0].sales)>=Number(dashboardData.my_targets_monthly[0].target)"
          :data="dashboardData.my_targets_monthly[0]"
        /></b-col>
      <b-col
        v-if="(typeof(dashboardData.show_stock_button) !=='undefined')"
        xl="3"
        md="3"
        sm="6"
      >
        <button-card
          title="Stok Gör"
          bg="danger"

          :to="{path:'/products/list'}"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.show_add_order_button) !=='undefined')"
        xl="3"
        md="3"
        sm="6"
      >
        <button-card
          title="Sipariş Gir"
          bg="danger"

          :to="{path:'/orders/add'}"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.show_add_proposal_button) !=='undefined')"
        xl="3"
        md="3"
        sm="6"
      >
        <button-card
          title="Teklif Oluştur"
          bg="danger"

          :to="{path:'/proposals/add'}"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.show_add_activity_button) !=='undefined')"
        xl="3"
        md="3"
        sm="6"
      >
        <button-card
          title="Faaliyet Oluştur"
          bg="danger"

          :to="{path:'/activity'}"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.show_training_notes_button) !=='undefined')"
        xl="5"
        md="5"
        sm="6"
      >
        <button-card
          title="Eğitimhane"
          bg="secondary"
          :to="{name:'my-trainings'}"
          description="Eğitim Notlarını Görmek İçin Tıklayın"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.show_tasks_button) !=='undefined')"
        xl="3"
        md="3"
        sm="6"
      >
        <button-card
          title="Görevlerim"
          bg="danger"

          :to="{path:'/apps/todo'}"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.show_documents_button) !=='undefined')"
        xl="2"
        md="4"
        sm="6"
      >
        <button-card
          title="Belgeler"
          url="url"
          bg="warning"
          :to="{name:'documents' }"
          description="Yüklü Belgelere Gitmek İçin Tıklayın"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.show_my_activities_button) !=='undefined')"
        xl="2"
        md="4"
        sm="6"
      >
        <button-card
          title="Faaliyetlerim"
          url="url"
          bg="warning"
          :to="{name:'my-activity' }"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.show_my_activities_button) !=='undefined')"
        xl="4"
        md="4"
        sm="6"
      >
        <exchange-rates />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.information_notes) !=='undefined')"
        xl="4"
        md="4"
        sm="6"
      >
        <information-notes :data="dashboardData.information_notes" />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.ongoing_campaigns) !=='undefined')"
        xl="4"
        md="4"
        sm="6"
      >
        <swiper-card :data="dashboardData.ongoing_campaigns" />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.all_targets_monthly) !=='undefined')"
        xl="4"
        md="6"
      >
        <dashboard-sales-target-single
          :title="'Aylık Hedef'"
          :subtitle="$staticParams.getMonthName(dashboardData.all_targets_monthly[0].month)+' Ayı Satışı'"
          :data="dashboardData.all_targets_monthly[0]"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.all_targets_current) !=='undefined')"
        xl="4"
        md="6"
      >
        <dashboard-sales-target-single
          :title="'Bu Aya Kadarki Hedef'"
          :subtitle="$staticParams.getMonthName(dashboardData.all_targets_current[0].month)+' Ayına Kadarki Satışı'"
          :data="dashboardData.all_targets_current[0]"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.all_targets_yearly) !=='undefined')"
        xl="4"
        md="6"
      >
        <dashboard-sales-target-single
          :title="'Yıllık Hedef'"
          :subtitle="dashboardData.all_targets_yearly[0].year+' Yılı Satışı'"
          :data="dashboardData.all_targets_yearly[0]"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.my_targets_monthly) !=='undefined')"
        xl="4"
        md="6"
      >
        <dashboard-sales-target-single
          :title="'Aylık Hedefim'"
          :subtitle="$staticParams.getMonthName(dashboardData.my_targets_monthly[0].month)+' Ayı Satışı'"
          :data="dashboardData.my_targets_monthly[0]"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.my_targets_current) !=='undefined')"
        xl="4"
        md="6"
      >
        <dashboard-sales-target-single
          :title="'Bu Aya Kadarki Hedefim'"
          :subtitle="$staticParams.getMonthName(dashboardData.my_targets_current[0].month)+' Ayına Kadarki Satışı'"
          :data="dashboardData.my_targets_current[0]"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.my_targets_yearly) !=='undefined')"
        xl="4"
        md="6"
      >
        <dashboard-sales-target-single
          :title="'Yıllık Hedefim'"
          :subtitle="dashboardData.my_targets_yearly[0].year+' Yılı Satışı'"
          :data="dashboardData.my_targets_yearly[0]"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.entered_orders_count) !=='undefined')"
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="FlagIcon"
          :statistic="dashboardData.entered_orders_count.order_count+' Adet'"
          statistic-title="Bu ay girdiğim siparişler"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.total_sales_all_users_this_month) !=='undefined')"
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="FlagIcon"
          :statistic="dashboardData.total_sales_all_users_this_month.order_count+' Adet'"
          statistic-title="Bu ayki siparişler (Tüm temsilciler dahil)"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.total_sales_all_users_this_month) !=='undefined')"
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="secondary"
          icon="FlagIcon"
          :statistic="dashboardData.total_sales_all_users_this_month.order_total+''+dashboardData.total_sales_all_users_this_month.main_currency_name"
          statistic-title="Bu ayki toplam sipariş tutarı (Tüm temsilciler)"
        />
      </b-col>

      <b-col
        v-if="(typeof(dashboardData.show_important_notes_button) !=='undefined')"
        xl="2"
        md="4"
        sm="6"
      >
        <button-card
          title="Önemli Notlar"
          bg="danger"
          description="Önemli Notları Görmek İçin Tıklayın"
          :to="{path:'/content/show-list/notes' }"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.show_ongoing_campaigns_button) !=='undefined')"
        xl="2"
        md="4"
        sm="6"
      >
        <button-card
          title="Devam Eden Kampanyalar"
          bg="warning"
          :to="{path:'/content/show-list/campaigns' }"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.my_targets_sales_graph_this_year) !=='undefined')"
        md="6"
      >
        <dashboard-sales-target-chart
          :data="dashboardData.my_targets_sales_graph_this_year"
          title="Hedef ve Satış Grafiğim"
        />
      </b-col>
      <b-col
        v-if="(typeof(dashboardData.all_targets_sales_graph_this_year) !=='undefined')"
        md="6"
      >
        <dashboard-sales-target-chart
          :data="dashboardData.all_targets_sales_graph_this_year"
          title="Tüm Temsilcilerin Toplam Hedef ve Satış Grafiği"
        />
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import DashboardSalesTargetSingle from './DashboardSalesTargetSingle.vue'
import DashboardSalesTargetChart from './DashboardSalesTargetChart.vue'

import CongratulationCard from './CongratulationCard.vue'
import ExchangeRates from './ExchangeRates.vue'

import ButtonCard from './ButtonCard.vue'
import InformationNotes from './InformationNotes.vue'
import swiperCard from './SwiperCard.vue'
import dashboardStoreModule from './dashboardStoreModule'

export default {
  components: {
    BRow,
    BCol,
    ExchangeRates,
    swiperCard,
    InformationNotes,
    StatisticCardVertical,
    DashboardSalesTargetSingle,
    CongratulationCard,
    ButtonCard,
    DashboardSalesTargetChart,
  },
  data() {
    return {

    }
  },
  setup() {
    const dashboardData = ref(null)
    const APP_STORE_MODULE_NAME = 'app-dashboard'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, dashboardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    store.dispatch('app-dashboard/fetchDashboardData', { list_type: 'all' })
      .then(response => {
        dashboardData.value = response.data.data
      })
    // data

    return { dashboardData }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
