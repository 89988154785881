<template>

  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>{{ $t('Döviz Kurları') }}</b-card-title>

    </b-card-header>

    <b-card-body>
      <div

        class="transaction-item"
      >
        <b-media no-body>

          <b-media-body>
            <h6 class="transaction-title">
              Döviz
            </h6>
            <small />
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder text-success"
        >
          Döviz Kuru
        </div>
        <div
          class="font-weight-bolder text-success"
        >
          Çapraz Kur (Usd)
        </div>
      </div>
      <div
        v-for="(item, index) in currencyOptions.filter(a=>a.is_main==false)"
        :key="index"
        class="transaction-item"
      >
        <b-media no-body>

          <b-media-body>
            <h6 class="transaction-title">
              {{ item.text }}
            </h6>
            <small />
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder text-success"
        >
          {{ item.currency }}
        </div>
        <div
          class="font-weight-bolder text-success"
        >
          {{ item.cross_rate_usd }}
        </div>
      </div>

    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BMedia, BMediaBody,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BMedia,
    BMediaBody,
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    bg: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      currencyOptions: [],
    }
  },
  created() {
    store.dispatch('app/fetchCurrencyTypes', {})
      .then(response => {
        const arr = []
        response.data.data.forEach(val => arr.push({
          text: val.name, value: val.id, is_main: val.is_main, currency: val.currency, cross_rate_usd: val.cross_rate_usd,
        }))
        this.currencyOptions = arr
      })
  },
}
</script>
